import * as React from 'react'
import Helmet from 'react-helmet'

import { GenericLayout } from './../layouts/generic'

export default () =>
  <GenericLayout>
    <Helmet>
      <title>About | Edouard Rigaudière - Film Composer</title>
      <meta property="og:title" content="About | Edouard Rigaudière - Film Composer" />
      <meta name="twitter:title" content="About | Edouard Rigaudière - Film Composer" />
    </Helmet>
    <main className="edrig-about">
      <div className="ed-rig-about__portrait"></div>
      <aside className="edrig-about__aside">
        <p className="edrig-about__description">
          Edouard Rigaudière is a Franco-British composer born in 1988. Trained first in classical music, then in jazz and modern music for several years, he begins a career as a film music composer in 2015. After several short films and commercials, he composes in 2016 the original-soundtrack of the series <span className="edrig-highlight">Loin de Chez Nous</span>, broadcasted on France 4 and Netflix. In 2017, he co-composes the music for the film <span className="edrig-highlight">Ghostland</span> by Pascal Laugier, which wins several international awards. This feature film marks the beginning of his collaboration with composer Anthony D’Amario, with whom he composes for several animated shorts and documentaries. In 2019, they meet again with Pascal Laugier to set to music the series <span className="edrig-highlight">They Were Ten</span>, a modern adaptation of Agatha Christie’s classic.<br />
          They recently wrote the score for the Disney Plus original series <span className="edrig-highlight">Les Amateurs</span> (The French Mans) and <span className="edrig-highlight">Cuisine Interne</span> (13 eme Rue / Universal +).
        </p>
        <p className="edrig-about__agents">
          Represented by Pierre Cattoni <br /><br />
          Agence <a href="http://www.troisieme-auteur.com/#/anthony-damario-edouard-rigaudiere" target="_blank">3ème auteur</a> <br /><br />
          <a href="mailto:pierre@troisieme-auteur.com">pierre@troisieme-auteur.com</a>
        </p>
        <p className="edrig-about__contact">
          +33 (0)6 33 11 21 74
          <br />
          <a href="mailto:edouard.rigaudiere@gmail.com">edouard.rigaudiere@gmail.com</a>
        </p>
        <div className="edrig-about__other">
          OTHER LINKS:
          <p className="edrig-about__flexcenter">
            Anthony D’amario & Edouard Rigaudière:
            <a href="https://soundcloud.com/ed-rig" target="_blank" rel="noopener noreferrer">
              <img src="https://static.cnginr.sbs/ed-rig-com/images/icons/soundcloud.png" alt="SoundCloud Logo" />
            </a>
          </p>
          <p className="edrig-about__flexcenter">
            DAYMARK:
            <a href="https://www.instagram.com/ed_rig/" target="_blank" rel="noopener noreferrer">
              <img src="https://static.cnginr.sbs/ed-rig-com/images/icons/instagram.png" alt="Instagram Logo" />
            </a>
            <a href="https://soundcloud.com/ed-rig" target="_blank" rel="noopener noreferrer">
              <img src="https://static.cnginr.sbs/ed-rig-com/images/icons/spotify.png" alt="Spotify Logo" />
            </a>
            <a href="https://soundcloud.com/ed-rig" target="_blank" rel="noopener noreferrer">
              <img src="https://static.cnginr.sbs/ed-rig-com/images/icons/soundcloud.png" alt="SoundCloud Logo" />
            </a>
          </p>
        </div>
      </aside>
    </main>
  </GenericLayout>
